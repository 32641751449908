import { Suspense } from "react";
import Header from '../components/header'
import Loader from "../components/loader";
import { SocketProvider } from "../helper/socket/SocketContext";
import { SnackbarProvider } from 'notistack';
const AuthorizedLayout = (props) => {
    let { children } = props;
    return (
        <>
            <SnackbarProvider>
                <SocketProvider>
                    <Suspense fallback={<Loader />}>
                        <Header children={children} />
                    </Suspense>
                </SocketProvider>
            </SnackbarProvider>
        </>
    )
}

export default AuthorizedLayout;