import { COLORS } from '../../assets/styles/colors';
import { makeStyles } from "@mui/styles";

/**
 * Write all login style here
 * Import it into respective component, screen
 * User the COLORS and Common Spacing while writing the styles
 */
const Loader = {
  loaderWrapper : {
    position : "fixed",
    left :0,
    right:0,
    top:0,
    bottom:0,
    background: "#fffffffa",
    zIndex: 9999
  },

   sectionLoader: {
    position : "absolute",
    left :0,
    right:0,
    top:0,
    bottom:0,
    background: "#fffffffa",
    zIndex: 9999
  }
}

export const LoaderStyle = makeStyles(() => Loader);


