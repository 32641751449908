import { COLORS } from '../styles/colors';
const MUIAppBar = {
    MuiAppBar: {
        styleOverrides: {
            root: {
                boxShadow : 'none',
                backgroundColor: `${COLORS.WHITE}`,
                borderBottom: `1px solid ${COLORS.LIGHT_GREY}`
            },
        },
    },
}

export {
    MUIAppBar
}