
// Dev url (https://dev.graphemelabs.ai)
import { API_ROOT, SOCKET_ROOT } from "../../envrionment";

const baseURL = API_ROOT;
const socketURL = SOCKET_ROOT;

const API_URLS = {
    help_pdf_url: `${baseURL}/help/documentation`,
    login_url: `${baseURL}/login/auth`,
    register_url: `${baseURL}/register`,
    search_form_field_url: `${baseURL}/search/form/fields`,
    vector_condition_field_url: `${baseURL}/search/vector/condition/fields`,
    search_result_url: `${baseURL}/search/elk/query`,
    search_result_export: `${baseURL}/search/elk/export`,
    searched_history_url: `${baseURL}/search/user/searched/history`,
    searched_history_archive_url: `${baseURL}/search/user/searched/archive`,
    searched_history_delete_url: `${baseURL}/search/user/searched/delete`,
    searched_one_patent_details_url: `${baseURL}/search/user/searched/one/patent/details`,
    mail_verification_url: `${baseURL}/authentication/email/verification`,
    forget_password_url: `${baseURL}/credentails/reset/password/request`,
    reset_password_url: `${baseURL}/credentails/reset/password/update`,
    project_save: `${baseURL}/project/save`,
    project_delete: `${baseURL}/project/delete`,
    all_user_project: `${baseURL}/project/all`,
    individual_project: `${baseURL}/project/one/details`,
    predicated_classfication_url: `${baseURL}/project/predicated/classification`,
    image_url: `${baseURL}/project/patent/s3/image`,
    save_relevant_text_url: `${baseURL}/project/save/relevant-text`,
    subscription_details_get: `${baseURL}/package/subscription/details/get`,
    service_offered_get: `${baseURL}/service/offered/get`,
    vector_search_fto_url: `${baseURL}/vector/search`,
    add_patent_claim_to_feature: `${baseURL}/vector/add/claim/feature`,
    vector_search_fs_fto_url: `${baseURL}/vector/fs/search/result`,
    vector_search_claim_term_url: `${baseURL}/vector/cst/compare`,
    project_filter_url: `${baseURL}/project/filter`,
    patent_family_details: `${baseURL}/project/patent/es/family/legal/status`,
    project_feature_recommendation: `${baseURL}/project/feature/recommendation`,
    project_feature_recommendation_list_update: `${baseURL}/project/feature/reommendation/list/update`,
    
    project_feature_tag_country : `${baseURL}/project/feature/tag/countries`,

    project_update_details: `${baseURL}/vector/update/claim`,
    project_word_cloud_generate: `${baseURL}/vector/generate/wc`,
    project_export_excel_report: `${baseURL}/export/report`,
    project_export_features_excel: `${baseURL}/export/features/excel`,
    project_export_project_excel: `${baseURL}/export/project/excel`,
    project_paginated_list_url: `${baseURL}/project/paginated/list`,
    project_patent_images: `${baseURL}/project/patent/fse/images`,
    project_patent_images_count: `${baseURL}/project/patent/images/count`,
   
    project_feature_list_url: `${baseURL}/project/features/list`,
    project_feature_claim_retry_url: `${baseURL}/vector/search/claim/retry`,
    project_feature_claim_cloud_retry_url: `${baseURL}/vector/search/claim/cloud/retry`,
    project_feature_relevant_claim_url: `${baseURL}/project/feature/relevant/claims`,
    project_similar_patent_claim_url: `${baseURL}/project/get/similar/patent/claims`,
    extracted_insights_url: `${baseURL}/project/qa`,
    settings_user_change_password: `${baseURL}/settings/user/change/password`,
    pdf_display_url: `${baseURL}/pdf/display`,
    delete_project: `${baseURL}/project/delete`,
    archive_project: `${baseURL}/project/archive`,
    project_filter_url: `${baseURL}/project/filter`,
    help_pdf_url: `https://graphemelabs.com/wp-content/uploads/2020/03/help_fIle_grapheme_tool.pdf`

}

export {
    API_URLS,
    socketURL
}