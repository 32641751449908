const MUIAppDialog = {
  MuiDialog: {
    styleOverrides: {
      root: {
        boxShadow: "none",
      },
    },
    defaultProps: {
      scroll: "body",
    },
  },
};

export { MUIAppDialog };
