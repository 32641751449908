const MUITextInput = {
    MuiInputBase: {
        styleOverrides: {
            input: {
                height: "1rem",
            },
        },
    },
    MuiFormControl: {
        styleOverrides: {
            root: {
                marginTop: "0px !important",
            },
        },
    },
    MuiOutlinedInput:{
        styleOverrides: {
            root: {
               borderRadius : "25px"
            },
        },
    },
    MuiInputLabel: {
        styleOverrides: {
            formControl: {
                'lineHeight': "1rem",
            },
        },
    },

}

export {
    MUITextInput
}