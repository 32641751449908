let fontFamily = ['Open Sans', 'sans-serif'];

const MUIFonts = {
    typography: {
        fontFamily: fontFamily.join(','),
    },

}

export {
    MUIFonts
}