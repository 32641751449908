const MuiList = {
    MuiList: {
        styleOverrides: {
            root: {
                padding: "0px",
                fontSize : "9px",
                cursor :"pointer",
            },
        },
    },
}

export {
    MuiList
}