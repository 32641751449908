const MUIButton = {
    MuiButton: {
        styleOverrides: {
            root: {
                fontSize: '12px',
                padding: "10px 24px",
                boxShadow: 'none',
                borderRadius: "25px",
                textTransform: "uppercase",
            },
            
        },
    },
    MuiButtonBase: {
        defaultProps: {
            disableRipple: true,
        },
    },
}

export {
    MUIButton
}