import { ROUTES } from "./app.router"
import AuthorizeLayout from "../layouts/authorize.layout";
import UnAuthorizeLayout from "../layouts/unauthorize.layout";
import { Route, Routes } from "react-router-dom";
import AuthorizeRoute from "./authorize.route";
import UnAuthorizeRoute from "./unauthorize.route";

const routes = [...ROUTES];

const AppRouters = (props) => {
    return (
        <Routes>
            {routes.map(({ component, isAuthenticationRequired, path }, key) => {
                return isAuthenticationRequired !== undefined && isAuthenticationRequired ? (
                    <Route
                        path={path}
                        key={key}
                        element={
                            <AuthorizeRoute>
                                <AuthorizeLayout>
                                    {component}
                                </AuthorizeLayout>
                            </AuthorizeRoute>
                        }
                    />
                ) : (
                    <Route
                        path={path}
                        key={key}
                        element={
                            <UnAuthorizeRoute>
                                <UnAuthorizeLayout>
                                    {component}
                                </UnAuthorizeLayout>
                            </UnAuthorizeRoute>
                        }
                    />
                )
            }
            )}

        </Routes>
    );
};

export default AppRouters;