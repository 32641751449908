import jwt_decode from "jwt-decode";

/**
 * Get data from Local storage
 * User has to pass the key for which he/she us quering
 * @param key 
 * @returns JSON/any return based on key
 */
let getFromLocalStorage = (key) => {
    return localStorage.getItem(key);
}

/**
 * Save data to Local storage based on key with data
 * @param key 
 * @param data 
 * @returns boolean
 */
let setDataToLocalStorage = (key, data) => {
    return localStorage.setItem(key, data);
}


/**
 * Remove data based on key
 * @param key 
 * @returns boolean
 */

let removeFromLocalStorage = (key) => {
    return localStorage.removeItem(key);
}

/**
 * Get all keys available into the Local storage
 * @returns all keys
 */
let getAllKeys = () => {
    return localStorage.getAllKeys();
}

/**
 * Check access token is expired on not if expired loggout the user
 * @returns boolean
 */
let isTokenExpired = async () => {
    let user = await getFromLocalStorage("user");
    let { token } = JSON.parse(user);
    if (token) {
        let { exp } = jwt_decode(token);
        if (exp) {
            if (Date.now() >= exp * 1000) {
                return true;
            } else {
                return false;
            }
        }
    }
    return true
}

/**
 * if user is logging out we are removing the token from storage
 * if token remove then user cann't enter into the dashboard
 * @returns boolean alwasys true
 */
let logout = () => {
    removeFromLocalStorage('loggedInUser');
    removeFromLocalStorage('token');
    return true;
}

/**
 * Get loggedin user from storage
 * this is data which saving after successfull login of user
 * @returns 
 */
let getLoggedInUserFromStorage = () => {
    let loggedInUser = getFromLocalStorage("user");
    if (loggedInUser) {
        return JSON.parse(loggedInUser);
    }
    return null;
}

export {
    getFromLocalStorage,
    setDataToLocalStorage,
    removeFromLocalStorage,
    getAllKeys,
    isTokenExpired,
    getLoggedInUserFromStorage,
    logout
}