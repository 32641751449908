const MUITypography = {
    MuiTypography: {
        styleOverrides: {
           
        },
    },
}

export {
    MUITypography
}