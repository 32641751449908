import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { unAuthorizeRequestAPI } from "../../helper/services/HTTPRequestAPISDK";
import { getFromLocalStorage, removeFromLocalStorage } from "../../helper/storage";

const user = getFromLocalStorage("user");
export const login = createAsyncThunk(
    "login",
    async ({ url, payload, method, headers = null }) => {
        try {
            const data = await unAuthorizeRequestAPI(url, payload, method).catch(error => {
                throw error
            });
            return { ...data };
        } catch (error) {
            return Promise.reject(JSON.stringify(error));
        }
    }
);

export const logout = createAsyncThunk("logout", async () => {
    removeFromLocalStorage('user');
});

const initialState = user
    ? { isLoggedIn: true, user }
    : { isLoggedIn: false, user: null };
const authenticationSlice = createSlice({
    name: "authentication",
    initialState,
    extraReducers: {
        [login.fulfilled]: (state, action) => {
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [login.rejected]: (state, action) => {
            state.isLoggedIn = false;
            state.user = null;
        },
        [logout.fulfilled]: (state, action) => {
            state.isLoggedIn = false;
            state.user = null;
        },
    },
});
const { reducer } = authenticationSlice;
export default reducer;