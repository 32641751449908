import { lighten } from '@mui/material/styles';
import { COLORS } from './colors';
import { makeStyles } from "@mui/styles";

/**
 * Write all common style here
 * Import it into respective component, screen
 * User the COLORS and Common Spacing while writing the styles
 */
const COMMON_STYLE = {

    fullWidth: {
        width: '100%'
    },
    width150px: {
        width: '150px'
    },
    ht32px: {
        height: '32px'
    },
    ht40px: {
        height: '40px'
    },
    ht42px: {
        height: '42px'
    },
    ht46px: {
        height: '46px'
    },
    ht120px: {
        height: '120px'
    },
    ht60px: {
        height: '60px'
    },
    ht64px: {
        height: '64px'
    },
    w50px: {
        width: '50px'
    },
    w20px: {
        width: '20px'
    },
    w35px: {
        width: '35px'
    },
    w100px: {
        width: '100px !important'
    },
    w60px: {
        width: '60px !important'
    },
    fullHeight: {
        height: '100%'
    },
    cp: { cursor: "pointer" },
    textCenter: {
        textAlign: 'center !important'
    },
    textLeft: {
        textAlign: 'left !important'
    },
    textRight: {
        textAlign: 'right !important'
    },
    textJustify: {
        textAlign: 'justify'
    },

    textUppercase: {
        textTransform: "uppercase",
    },

    textLowercase: {
        textTransform: "lowercase",
    },

    textCapitalize: {
        textTransform: "capitalize",
    },
    py8:{
        paddingTop :"8px" ,
        paddingBottom :"8px" 
    },
   
    a_link: {
        color: COLORS.BLUE,
        textDecoration: "underline",
        "&:hover": {
            color: COLORS.BLUE,
            textDecoration: "underline"
        },
        "&:focus": {
            color: COLORS.BLUE,
            textDecoration: "underline"
        },
        "&:visited": {
            color: COLORS.BLUE,
            textDecoration: "underline"
        }
    },
    fs6: {
        fontSize: "60% !important"
    },
    fs7: {
        fontSize: "70% !important"
    },
    fs8: {
        fontSize: "80% !important"
    },
    fs9: {
        fontSize: "90% !important"
    },
    fs95: {
        fontSize: "95% !important"
    },
    fs10: {
        fontSize: "100% !important"
    },
    fs11: {
        fontSize: "110% !important"
    },
    fs12: {
        fontSize: "120% !important"
    },
    fs13: {
        fontSize: "130% !important"
    },
    fs14: {
        fontSize: "140% !important"
    },
    fs15: {
        fontSize: "150% !important"
    },
    fs20: {
        fontSize: "200% !important"
    },
    textMuted: {
        color: COLORS.GREY2
    },
    textDark: {
        color: `${COLORS.BLACK} !important`
    },
    borderPrimary : {
        border : `4px solid ${lighten(COLORS.PRIMARY,0.6)} !important`
    },
    borderWhite : {
        border : `4px solid ${COLORS.WHITE} !important`
    },
    textLightBlack: {
        color: COLORS.GREY1
    },
    textLightGery4: {
        color: COLORS.GREY4
    },
    textBlue: {
        color: `${COLORS.BLUE} !important`
    },
    textPrimary: {
        color: `${COLORS.PRIMARY} !important`
    },
    bgPrimaryLight: {
        backgroundColor: `${lighten(COLORS.PRIMARY,0.9)} !important`
    },
    textDanger: {
        color: `${COLORS.RED} !important`
    },
    textWhite: {
        color: `${COLORS.WHITE} !important`
    },
    textWarning: {
        color: `${COLORS.WARNING} !important`
    },
    textSuccess : {
        color: `${COLORS.SUCCESS} !important`
    },
    textSecondary: {
        color: `${COLORS.SECONDARY} !important`
    },
    bgSecondary: {
        backgroundColor: `${COLORS.SECONDARY} !important`
    },
  
    bgLight: {
        backgroundColor: `${COLORS.LIGHT_GREY} !important`
    },
    bgWhite: {
        backgroundColor: `${COLORS.WHITE} !important`
    },
    bgGREY4: {
        backgroundColor: `${COLORS.GREY4} !important`
    },
    bgPrimary: {
        backgroundColor: `${COLORS.PRIMARY} !important`
    },
    bgWarning: {
        backgroundColor: `${COLORS.WARNING} !important`
    },
    bgError: {
        backgroundColor: `${COLORS.ERROR} !important`
    },
    br4: {
        borderRadius: '4px'
    },
    fw600: {
        fontWeight: "600 !important"
    },
    p16: {
        padding: '16px'
    },
    p8: {
        padding: '8px'
    },
    p0: {
        padding: '0px'
    },
    ml8: {
        marginLeft: '8px'
    },
    mr8: {
        marginRight: '8px'
    },
    vt: {
        verticalAlign: 'top'
    },
    vm: {
        verticalAlign: 'middle'
    },
    headerTable: {
        height: "100vh",
        width: "100% !important",
        tableLayout: "fixed",
        borderSpacing: 0
    },
    tableLayoutFixed: {
        tableLayout: 'fixed'
    },

    rounded: {
        borderRadius: '25px'
    },
    br8: {
        borderRadius: '8px'
    },
    br4: {
        borderRadius: '4px'
    },

    bgPrimaryOPC5: {
        backgroundColor : lighten(COLORS.PRIMARY,0.5)
    },

    bgSecondaryOPC5: {
        backgroundColor : lighten(COLORS.SECONDARY,0.5)
    },

    formControl:{
        height:"36px",
        borderRadius:"25px",
        width:'100%',
        border : `1px solid  ${lighten(COLORS.PRIMARY, 0.8)}`,
        padding: '0px 12px',
        outline : 'none',
        background : `${lighten(COLORS.PRIMARY, 0.8)} !important`,
        "&:hover": {
            border : `1px solid ${lighten(COLORS.PRIMARY, 0.7)}`,
            outline : 'none',
            background : `${lighten(COLORS.PRIMARY, 0.7)} !important`,
        },
        "&:focus": {
            border : `1px solid ${lighten(COLORS.PRIMARY, 0.7)}`,
            outline : 'none',
            background : `${lighten(COLORS.PRIMARY, 0.7)} !important`,
        },
        "&:visited": {
            border : `1px solid ${lighten(COLORS.PRIMARY, 0.7)}`,
            outline : 'none',
            background : `${lighten(COLORS.PRIMARY, 0.7)} !important`,
        }
    },

    imageWrapperWidth : {
        width : "150px"
    },

    Datepicker : {
        height : "36px", 
        borderRadius : "25px",
        borderRadius:"25px",
        width:'100%',
        border : `1px solid  #ddd`,
        padding: '0px 12px',
        outline : 'none',
        "&:hover": {
            border : `1px solid #ddd`,
            outline : 'none',
        },
        "&:focus": {
            border : `1px solid #ddd`,
            outline : 'none',
        },
        "&:visited": {
            border : `1px solid #ddd`,
            outline : 'none',
        }
    },
}
 

export const CS = makeStyles(() => COMMON_STYLE);


