import { COLORS } from '../styles/colors';
const MUICard = {
    MuiCard: {
        styleOverrides: {
            root: {
                boxShadow : 'none',
                backgroundColor: `${COLORS.WHITE}`,
                border: `1px solid ${COLORS.LIGHT_GREY}`,
                borderRadius:"8px"
            },
        },
    },
}

export {
    MUICard
}