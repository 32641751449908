import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import jwt_decode from "jwt-decode";
const AuthorizeRoute = (props) => {
    const location = useLocation();
    let key = location.pathname;

    let { isLoggedIn, user } = useSelector((state) => state.authentication);

    useEffect(() => {

    }, []);

    switch (key) {
        case "/subscription/price":
            return props.children;
        default:
            if (!isLoggedIn) {
                return <Navigate to="/login" state={{ from: location }} />;
            }
    }
    return props.children;
};

export default AuthorizeRoute;
