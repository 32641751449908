
import React, { lazy } from "react";



const ForgotPassword = lazy(() => import("../screens/unauthorized/forget_password"));
const Login = lazy(() => import("../screens/unauthorized/login"));
const MailVerification = lazy(() => import("../screens/unauthorized/mail_verification"));
const Register = lazy(() => import("../screens/unauthorized/register"));
const ResetPassword = lazy(() => import("../screens/unauthorized/reset_password"));
const Subscription = lazy(() => import("../screens/unauthorized/subscription"));
const PageNotFound = lazy(() => import("../screens/unauthorized/error/404"));
const Project = lazy(() => import("../screens/authorized/vector/project"));
const ProjectDetails = lazy(() => import("../screens/authorized/vector/project_details"));
const VectorSearchResult = lazy(() => import("../screens/authorized/vector/search_result"));
const ProjectAnalysis = lazy(() => import("../screens/authorized/vector/project_analysis"));
const VectorSearch = lazy(() => import("../screens/authorized/vector/search"));
const Patent = lazy(() => import("../screens/authorized/patent"));

const SettingProfile = lazy(() => import("../screens/authorized/settings/profile"));
const SettingPassword = lazy(() => import("../screens/authorized/settings/password"));
const SettingAccount = lazy(() => import("../screens/authorized/settings/account"));
const SettingSubscriptions = lazy(() => import("../screens/authorized/settings/subscriptions"));
const SettingInvoice = lazy(() => import("../screens/authorized/payment/invoice"));
const SettingPaymentHistory = lazy(() => import("../screens/authorized/payment/payment_history"));
const SettingSavedCard = lazy(() => import("../screens/authorized/payment/saved_card"));
const Logout = lazy(() => import("../screens/authorized/logout"));
const EditProject = lazy(() => import("../screens/authorized/vector/edit_project"));

const ROUTES = [
    { path: "/login", component: <Login />, isAuthenticationRequired: false },
    { path: "/subscription/price", component: <Subscription />, isAuthenticationRequired: false },
    { path: "/register", component: <Register />, isAuthenticationRequired: false },
    { path: "/forgot/password", component: <ForgotPassword />, isAuthenticationRequired: false },
    { path: "/reset/password", component: <ResetPassword />, isAuthenticationRequired: false },
    { path: "/mail/verified", component: <MailVerification />, isAuthenticationRequired: false },
    { path: "/", component: <Project />, isAuthenticationRequired: true },
    { path: "/create/project", component: <VectorSearch />, isAuthenticationRequired: true },
    { path: "/search/result", component: <VectorSearchResult />, isAuthenticationRequired: true },
    { path: "/edit/project", component: <EditProject />, isAuthenticationRequired: true },
    { path: "/patent/details", component: <Patent />, isAuthenticationRequired: true },
    { path: "/project/details", component: <ProjectDetails />, isAuthenticationRequired: true },
    { path: "/project/analysis", component: <ProjectAnalysis />, isAuthenticationRequired: true },
    { path: "/settings/profile", component: <SettingProfile />, isAuthenticationRequired: true },
    { path: "/settings/account", component: <SettingAccount />, isAuthenticationRequired: true },
    { path: "/settings/password", component: <SettingPassword />, isAuthenticationRequired: true },
    { path: "/settings/subscriptions", component: <SettingSubscriptions />, isAuthenticationRequired: true },
    { path: "/payment/invoice", component: <SettingInvoice />, isAuthenticationRequired: true },
    { path: "/payment/history", component: <SettingPaymentHistory />, isAuthenticationRequired: true },
    { path: "/payment/saved/card", component: <SettingSavedCard />, isAuthenticationRequired: true },
    { path: "/logout", component: <Logout />, isAuthenticationRequired: true },
    { path: "*", component: <PageNotFound />, isAuthenticationRequired: false },
]
export {
    ROUTES
}