const MUIAppSelect = {
    MuiSelect: {
        styleOverrides: {
            root: {
                height: '45px',
            },
        },
    },
   
}

export {
    MUIAppSelect
}