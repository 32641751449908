import { Suspense } from "react";
import Loader from "../components/loader";
const UnAuthorizedLayout = (props) => {
    let { children } = props;
    return (
        <>
            <Suspense fallback={<Loader/>}>
                <div>{children}</div>
            </Suspense>
        </>
    )
}

export default UnAuthorizedLayout;